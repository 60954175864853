import { Component, OnInit } from '@angular/core';

import { SecurityService } from './shared/services/security/security.service';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pac-front';
  environment = environment;
  dT_;

  constructor(
    private readonly securityService: SecurityService,
    private readonly http: HttpClient,
    private cookieService: CookieService
  ) {
    if (typeof this.dT_!='undefined' && this.dT_.initAngularNg) {
      this.dT_.initAngularNg(this.http, HttpHeaders);
    }
  }

  ngOnInit() {
    const cookieExists: boolean = this.cookieService.check('SMSESSION');
    if(cookieExists){
      this.cookieService.delete('SMSESSION','/','.sicredi.net');
      this.cookieService.delete('SMSESSION','/','.sicredi.com.br');
    }
    this.securityService.login();
  }
}
