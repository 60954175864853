import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { UserService } from "../user/user.service";
import { environment } from "../../../../environments/environment";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  constructor(
    private readonly oauthService: OAuthService,
    private readonly userService: UserService
  ) {
    oauthService.configure(environment.oauth);
    oauthService.setStorage(localStorage);
  }

  public login() {
    console.info("Security Service: Login");
    this.oauthService.tryLogin();
    this.oauthService.events
      .pipe(
        filter((e) => ["token_received", "token_refreshed"].includes(e.type))
      )
      .subscribe((_) => {
        const accessToken = this.oauthService.getAccessToken();
        this.userService.authenticate(accessToken);
      });
    this.onRefreshToken();
    //this.checkAuthenticate();
  }

  onRefreshToken() {
    const expirationTime = localStorage.getItem("expires_at");
    const nowTime = new Date().getTime();
    const diffTime = (parseInt(expirationTime) - nowTime) / 60 / 1000;
    const validationTime = 1.5;
    console.info(`Token expire in ${parseInt(diffTime.toString())}s`);
    if (diffTime <= validationTime) {
      console.info("Execute refresh token now");
      this.oauthService.silentRefresh();
    }

    setTimeout(() => this.onRefreshToken(), 30000);
  }

  checkAuthenticate() {
    const user = this.userService.getUser();
    console.info("User iniatializer: ", user);
    if (
      !user ||
      (!!user &&
        (!user.name ||
          !user.accessToken ||
          !user.username ||
          !user.locations ||
          (!!user.locations && !user.locations.length) ||
          !user.email))
    ) {
      this.userService.cleanStorage();
      const accessToken = this.oauthService.getAccessToken();
      this.userService.authenticate(accessToken);
    }
  }
}
