<div class="advanced-search-dialog dialog">
  <h1 mat-dialog-title class="title">Mudar cooperativa</h1>
  <div class="close float-right">
    <button [mat-dialog-close]=""><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
      <div class="border-alert">
        <img
          style="padding-bottom: 4px"
          src="../../../../../assets/images/ic-alert.svg"
          alt="ic-alert.svg"
          class="ic-alert"
        />
        <div class="notification">
          <span
            >Ao mudar a cooperativa, todas as alterações não salvas serão
            descartadas.</span
          >
        </div>
      </div>

      <div class="select-coop">
        <mat-select-autocomplete
          class="error-text_autocomplete multiselect-actions multiselect actions-select"
          [errorMsg]="'Campo obrigatório. Você deve preenchê-lo para seguir'"
          [selectPlaceholder]="'Pesquisar...'"
          [placeholder]="'Selecione a cooperativa'"
          [options]="allCoops"
          [multiple]="false"
          ngDefaultControl
          (selectionChange)="coopSelected($event)"
        >
        </mat-select-autocomplete>
      </div>
  </div>
</div>

<div class="row buttons dialog-buttons">
  <div class="col-6">
    <button class="btn btn-outline-success back" [mat-dialog-close]="">
      Fechar
    </button>
  </div>

  <div class="col-6">
    <button class="btn btn-success search" [mat-dialog-close]="" (click)="changeCoopAction()">Mudar cooperativa</button>
  </div>
</div>
