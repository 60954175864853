import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SnackbarService } from "../snackbar/snackbar.service";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private readonly snackbarService: SnackbarService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e) => {
        if (e instanceof HttpErrorResponse) {
          if (e.error.status === 503) {
            this.snackbarService.openSnackbar(
              "Serviço temporariamente indisponível. Por favor, tente recarregar a página ou aguardar por alguns minutos e tente novamente.",
              "default-snackbar"
            );
          } else if (e.error.status >= 500) {
            this.snackbarService.openSnackbar(
              "Ocorreu um erro inesperado. Por favor, tente recarregar a página ou aguardar por alguns minutos e tente novamente.",
              "error-snackbar"
            );
          }
          console.error(e.error);
        }
        return next.handle(req);
      })
    );
  }
}
