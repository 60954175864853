<!-- <app-header></app-header>
<div class="row">
    <div class="col-2">
        <app-menu></app-menu>
    </div>
    <div class="col-10">
        <router-outlet></router-outlet>
    </div>
</div>
 -->
 <app-loader></app-loader>
 <router-outlet></router-outlet>
