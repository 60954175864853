import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../models/user';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  APPLICATION_JSON = 'application/json';

  constructor(
    private readonly userService: UserService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user: User = this.userService.getUser();
    if (user && user.accessToken) {
      let clonedRequest: HttpRequest<any>;
      if (req.body && req.body instanceof FormData) {
        clonedRequest = req.clone({
          headers: req.headers
            .set('Authorization', 'Bearer ' + user.accessToken)
            .set('Accept', this.APPLICATION_JSON)
        });
      } else {
        clonedRequest = req.clone({
          headers: req.headers
            .set('Content-Type', this.APPLICATION_JSON)
            .set('Authorization', 'Bearer ' + user.accessToken)
            .set('Accept', this.APPLICATION_JSON)
        });
      }
      return next.handle(clonedRequest);
    } else {
      return next.handle(req);
    }
  }

}
