import { AuthConfig } from "angular-oauth2-oidc";

export class Environment {
  MARKETING_ROLE_NAME: string;
  CRM_GENERIC_ROLE_NAME: string;
  CRM_ROLE_NAME: string;
  CRM_ADMIN_ROLE_NAME: string;
  COOPERATIVE_GENERIC_ROLE_NAME: string;
  COOPERATIVE_ROLE_NAME: string;
  COOPERATIVE_ADMIN_ROLE_NAME: string;
  production: boolean;
  oauth: AuthConfig;
  apiUrl: string;
}

// export mock environment for Test
export let environment: Environment = {
  production: false,
  MARKETING_ROLE_NAME: "pac_marketing",
  CRM_GENERIC_ROLE_NAME: "pac_crm",
  CRM_ROLE_NAME: "pac_gestaodeestrategia_crm",
  CRM_ADMIN_ROLE_NAME: "pac_gestaodeestrategia_crm_admin",
  COOPERATIVE_GENERIC_ROLE_NAME: "pac_cooperativa",
  COOPERATIVE_ROLE_NAME: "pac_gestaodeestrategia_visualizacao",
  COOPERATIVE_ADMIN_ROLE_NAME: "pac_gestaodeestrategia_coop_personaliza",
  apiUrl: "http://localhost:8100/assets/data/",
  oauth: {
    issuer: "https://wso2identity.hom.sicredi.net/oauth2/token",
    loginUrl: "https://wso2identity.hom.sicredi.net/oauth2/authorize",
    logoutUrl: "https://wso2identity.hom.sicredi.net/oidc/logout",
    postLogoutRedirectUri: "https://cookieprovider-hom.sicredi.com.br/logout_redirect.html?url=http://localhost:8100",
    clientId: "XDF_W7a1t0AbqlWeqLP9zBmcBWYa",
    scope: "openid profile email",
    redirectUri: "http://localhost:8100",
    waitForTokenInMsec: 1000,
  },
};

export function setEnvironment(env: Environment): void {
  environment = env;
}
