import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { StorageService } from '../storage/storage.service';
import { User } from '../../models/user';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '../../models/location';

class AuthReturn {
  username: string;
  email: string;
  name: string;
  locations: Location[] = [];
  role: string;
  profiles: string[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly storageService: StorageService,
    private readonly oauthService: OAuthService,
    private readonly http: HttpClient,
    private cookieService: CookieService
  ) {
    oauthService.configure(environment.oauth);
    oauthService.setStorage(localStorage);
   }

  authenticate(accessToken: string): void {
    let user: User = new User(accessToken);
    this.storageUser(user);
    const cookieExists: boolean = this.cookieService.check('SMSESSION');
    if(cookieExists){
      this.cookieService.delete('SMSESSION','/','.sicredi.net');
      this.cookieService.delete('SMSESSION','/','.sicredi.com.br');
    }
    this.http.get<AuthReturn>(environment.apiUrl + '/auth').toPromise()
      .then(data => {
        const claims = this.oauthService.getIdentityClaims()
        user = Object.assign(user, {
          ...data,
          username: claims["sub"],
          name: claims["name"],
          profiles: this.getRoles(claims["role"]),
          email: claims["email"],
        });
        if(!user.profiles.includes(environment.CRM_ADMIN_ROLE_NAME)){
          user.allCoops = data.locations;
          user.locations = [];
          user.locations.push(data.locations[0]);
        }
        this.storageUser(user);
      })
      .then(() => window.location.href="/portal");
  }

  private getRoles(roles: string[]) {
    const profiles = [
      environment.COOPERATIVE_ADMIN_ROLE_NAME,
      environment.COOPERATIVE_GENERIC_ROLE_NAME,
      environment.COOPERATIVE_ROLE_NAME,
      environment.CRM_ADMIN_ROLE_NAME,
      environment.CRM_GENERIC_ROLE_NAME,
      environment.CRM_ROLE_NAME,
      environment.MARKETING_ROLE_NAME
    ]
    
    return roles
      .filter(it => profiles.some(role => it.match(role) !== null))
      .map(it => profiles.find(role => it.match(role) !== null))
  }

  storageUser(user: User): void {
    this.storageService.set('user', user);
  }

  cleanStorage(): void {
    this.storageService.remove('user');
  }

  logout() {
    this.oauthService.logOut(false);
    localStorage.removeItem('user');
    localStorage.removeItem('jwt');
  }

  getUser(): User {
    return this.storageService.get('user');
  }

  isAuthenticated(): boolean {
    const user: User = this.storageService.get('user');
    if (user && user.name && user.profiles && user.profiles.length === 0) {
      return false;
    }
    return true;
  }
}
