import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";

import { CommonModule } from "@angular/common";
import { ChangeCoopDialogComponent } from "./change-coop-dialog.component";
import { NgModule } from "@angular/core";
import { OAuthModule } from "angular-oauth2-oidc";
import { SelectAutocompleteModule } from "mat-select-autocomplete";

@NgModule({
  declarations: [ChangeCoopDialogComponent],
  entryComponents: [ChangeCoopDialogComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    SelectAutocompleteModule,
    MatSelectModule,
    SatNativeDateModule,
    SatDatepickerModule,
    OAuthModule.forRoot(),
  ],
  exports: [ChangeCoopDialogComponent],
})
export class ChangeCoopDialogModule {}