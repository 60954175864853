import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OcticonModule } from '../../directives/octicon/octicon.module';


@NgModule({
  declarations: [
    SnackbarComponent
  ],
  entryComponents: [ SnackbarComponent ],
  imports: [
    OcticonModule,
    MatSnackBarModule,
    CommonModule
  ],
  exports: [
    SnackbarComponent
  ]
})
export class SnackbarModule { }
