import { Environment, setEnvironment } from '../../../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  public init(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.http.get('assets/environments/environment.json').toPromise()
      .then(result => {
        let environment: Environment = new Environment();
        environment = Object.assign(environment, result);
        setEnvironment(environment);
        resolve();
      })
      .catch(err => {
        reject(err);
      });
    });
  }
}
