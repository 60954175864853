import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from '../loader/loader.service';
@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {

  timer: any;
  constructor(public loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.timer){
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => this.loaderService.show(), 10);

    return next.handle(req).pipe(
      finalize(() => {
        this.loaderService.hide();
        if(this.timer){
          clearTimeout(this.timer);
        }
      })
    );
  }
}
