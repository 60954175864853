import { Location } from './location';

export class User {
    accessToken: string;
    username: string;
    email: string;
    name: string;
    locations: Location[] = [];
    profiles: string[] = [];
    allCoops: Location[] = [];

    constructor(accessToken: string) {
        this.accessToken = accessToken;
    }
}
