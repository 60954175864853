import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppConfigService } from "./shared/services/app-config/app-config.service";
import { AppRoutingModule } from "./app-routing.module";
import { AuthInterceptorService } from "./shared/services/auth-interceptor/auth-interceptor.service";
import { BrowserModule } from "@angular/platform-browser";
import { HttpInterceptorService } from "./shared/services/http-interceptor/http-interceptor.service";
import { LoaderInterceptorService } from "./shared/services/loader-interceptor/loader-interceptor.service";
import { LoaderModule } from "./shared/components/loader/loader.module";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { OAuthModule } from "angular-oauth2-oidc";
import { OcticonModule } from "./shared/directives/octicon/octicon.module";
import { SnackbarModule } from "./shared/components/snackbar/snackbar.module";
import { CookieService } from "ngx-cookie-service";
import { FormsModule } from '@angular/forms';
import { ChangeCoopDialogModule } from "./shared/components/change-coop-dialog/change-coop-dialog.module";
import { MatChipsModule } from "@angular/material/chips";

export function init_app(appConfigService: AppConfigService) {
  return () => appConfigService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    NoopAnimationsModule,
    OcticonModule,
    OAuthModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatSnackBarModule,
    ChangeCoopDialogModule,
    MatChipsModule,
    SnackbarModule,
    LoaderModule,
    FormsModule,
  ],
  providers: [
    AppConfigService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppConfigService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
