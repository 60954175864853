import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/services/auth-guard/auth-guard.service';
import { AuthGuardAuthorized } from './shared/services/auth-guard-authorized/auth-guard-authorized';
import { AuthGuardUnauthorized } from './shared/services/auth-guard-permission/auth-guard-unauthorized';
import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: '', redirectTo: 'portal', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'portal', loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule), canActivate: [AuthGuard, AuthGuardAuthorized] },
  { path: 'pac', loadChildren: () => import('./pac/pac.module').then(m => m.PacModule), canActivate: [AuthGuard, AuthGuardAuthorized] },
  { path: 'unauthorized', loadChildren: () => import('./unauthorized/unauthorized.module').then(m => m.UnauthorizedModule), canActivate: [AuthGuard, AuthGuardUnauthorized] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
