import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [LoaderComponent],
  entryComponents: [LoaderComponent],
  imports: [
    CommonModule,
    BrowserModule,
    MatProgressSpinnerModule
  ],
  exports: [LoaderComponent]
})
export class LoaderModule { }
